import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("my_leads");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

//dept SetUp list
export const getSetUpCompanyRoleslabel = createAsyncThunk(
    "report/getSetUpCompanyRoleslabel",
    async () => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/company-setup/`,
                getAuthHeaders()
            );
            // console.log("getSetUpCompanyRoleslabel==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const AddSingleSetUpCompanyRoleslabel = createAsyncThunk(
    "report/AddSingleSetUpCompanyRoleslabel",
    async (data) => {
        try {
            const response = await BaseUrl.post(
                `my_Leads/company-setup`, data,
                getAuthHeaders()
            );
            console.log("AddSingleSetUpCompanyRoleslabel==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getSingleSetUpCompanyRoleslabel = createAsyncThunk(
    "report/getSingleSetUpCompanyRoleslabel",
    async (id) => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/company-setup/${id}`,
                getAuthHeaders()
            );
            // console.log("getSingleSetUpCompanyRoleslabel==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getCompanyRoles = createAsyncThunk(
    "report/getCompanyRoles",
    async (id) => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/company-setup/company/${id}`,
                getAuthHeaders()
            );
            // console.log("getCompanyRoles==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const UpdateSingleSetUpCompanyRoleslabel = createAsyncThunk(
    "report/UpdateSingleSetUpCompanyRoleslabel",
    async (data) => {
        try {
            const response = await BaseUrl.patch(
                `my_Leads/company-setup`, data,
                getAuthHeaders()
            );
            // console.log("UpdateSingleSetUpCompanyRoleslabel==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getAllCompanyDropDown = createAsyncThunk(
    "report/getAllCompanyDropDown",
    async (id) => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/company-setup-dropdown/${id}`,
                getAuthHeaders()
            );
            console.log("getAllCompanyDropDown==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getSingleCompanyDropDown = createAsyncThunk(
    "report/getSingleCompanyDropDown",
    async (id) => {
        try {
            const response = await BaseUrl.get(`/my_Leads/company-setup-dropdown/set-up/${id}`, getAuthHeaders());
            console.log("getSingleCompanyDropDown==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const AddSingleCompanyDropDown = createAsyncThunk(
    "report/AddSingleCompanyDropDown",
    async (data) => {
        try {
            const response = await BaseUrl.post(
                `my_Leads/company-setup-dropdown/`, data,
                getAuthHeaders()
            );
            // console.log("UpdateSingleSetUpCompanyRoleslabel==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const UpdateSingleCompanyDropDown = createAsyncThunk(
    "report/UpdateSingleCompanyDropDown",
    async (data) => {
        try {
            const response = await BaseUrl.patch(
                `/my_Leads/company-setup-dropdown/`, data,
                getAuthHeaders()
            );
            console.log("UpdateSingleCompanyDropDown==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const roleSetupSlice = createSlice({
    name: "rolesetup",
    initialState: {
        roleLabelsData: [],
        companyRoles: {},
        singleroleLabelData: {},
        companyDropDown: [],
        singleDropDown: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSetUpCompanyRoleslabel.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSetUpCompanyRoleslabel.fulfilled, (state, action) => {
                state.loading = false;
                state.roleLabelsData = action.payload;
            })
            .addCase(getSetUpCompanyRoleslabel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getSingleSetUpCompanyRoleslabel.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleSetUpCompanyRoleslabel.fulfilled, (state, action) => {
                state.loading = false;
                state.singleroleLabelData = action.payload;
            })
            .addCase(getSingleSetUpCompanyRoleslabel.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getCompanyRoles.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyRoles.fulfilled, (state, action) => {
                state.loading = false;
                state.companyRoles = action.payload;
            })
            .addCase(getCompanyRoles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getAllCompanyDropDown.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllCompanyDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.companyDropDown = action.payload;
            })
            .addCase(getAllCompanyDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getSingleCompanyDropDown.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleCompanyDropDown.fulfilled, (state, action) => {
                state.loading = false;
                state.singleDropDown = action.payload;
            })
            .addCase(getSingleCompanyDropDown.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});
export const setUpCompanyRoleslabelList = (state) => state.rolesetup.roleLabelsData;
export const getUniqueCompanyRoleslabel = (state) => state.rolesetup.singleroleLabelData;
export const companyRolesBasedOnCId = (state) => state.rolesetup.companyRoles;
export const getCompanyDropDown = (state) => state.rolesetup.companyDropDown;
export const getUniqueDropDown = (state) => state.rolesetup.singleDropDown;
export const setUpCompanyRoleslaberror = (state) => state.rolesetup.loading;

// EXPORTS
export default roleSetupSlice.reducer;
