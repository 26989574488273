import { ArrowBackIosNewOutlined, Dashboard, MenuOutlined, Settings } from "@mui/icons-material";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
// import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
// import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import ContactPageRoundedIcon from "@mui/icons-material/ContactPageRounded";
import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { UseAuth } from "../customhooks/UseAuth";
import {
  fetchPermissions,
  getPermissions,
} from "../redux/reducerSlice/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { companyRolesBasedOnCId } from "../redux/reducerSlice/SetUpSlice";

const Items = ({ label, icon, color, onClickAction, active }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Tooltip title={label} placement="right-start">
      <div>
        <MenuItem
          active={active}
          style={{
            // color: color,
            background: active ? "#0077AA" : isHovered ? "#0077AA" : "#ffffff", // Change background color on hover
            color: active ? "#ffffff" : isHovered ? "#ffffff" : "#0077AA",
            borderStartStartRadius: 20,
            borderEndStartRadius: 20,
            marginTop: active ? "0.2em" : isHovered ? "0.2em" : "0.2em",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          icon={icon}
          onClick={onClickAction}
        >
          <Typography sx={{ fontSize: 14, fontFamily: "inherit" }}>{label}</Typography>
        </MenuItem>
      </div>
    </Tooltip>
  );
};
// const SubMenuItem = ({ label, onClickAction, active }) => {
//   const [isHovered, setIsHovered] = useState(false);
//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };
//   return (
//     <div>
//       <MenuItem
//         active={active}
//         style={{
//           background: active ? "#0077AA" : isHovered ? "#0077AA" : "#ffffff", // Change background color on hover
//           color: active ? "#ffffff" : isHovered ? "#ffffff" : "black",
//           borderStartStartRadius: 20,
//           borderEndStartRadius: 20,
//           marginTop: active ? "0.2em" : isHovered ? "0.2em" : "0.2em",
//         }}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         onClick={onClickAction}
//       >
//         <Typography>{label}</Typography>
//       </MenuItem>
//     </div>
//   );
// };
const SideBarComponent = () => {
  const dispatch = useDispatch();
  const { role } = UseAuth();
  const [permissions, setpermissions] = useState();
  const fetchpermissions = useSelector(getPermissions);
  const rolesNames = useSelector(companyRolesBasedOnCId)
  const [show, setHide] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [companyData, setCompanyData] = useState({})
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);
  const handlePermissions = () => {
    // console.log("fetch permission===>", fetchpermissions);
    const jsonString = fetchpermissions.permisssions;
    // console.log("json string", jsonString);
    if (jsonString) {
      localStorage.setItem("permissions", jsonString);
      setpermissions(JSON.parse(jsonString));
      // console.log("permissions: ", permissions);
    }
  };
  useEffect(() => {
    if (fetchpermissions) {
      handlePermissions();
    }
  }, [fetchpermissions]);
  const handleNavigation = (path) => {
    navigate(path);
    setActiveItem(path); // Update the active item when navigating
  };
  useEffect(() => {
    const getStoreData = () => {
      let companyLogoStore = JSON.parse(window.localStorage.getItem("company_logo"));
      setCompanyData(companyLogoStore)
    }
    getStoreData()
    // Extract the pathname from location object and set the active item accordingly
    setActiveItem(location.pathname);
  }, [location]);

  const logOutHandler = () => {
    navigate("/");
    // localStorage.clear("my_leads");
    // localStorage.clear("company_logo");

    let currentHostname = window.location.hostname;
    // Split the hostname to separate subdomain from the rest
    let newHostname;
    if (currentHostname.includes('localhost')) {
      newHostname = `localhost`;
    } else {
      newHostname = `myleads.in`;
    }
    const parts = currentHostname.split('.');
    console.log("parts", parts)
    localStorage.clear("my_leads");
    localStorage.clear("company_logo");
    window.location.href = `${window.location.protocol}//${newHostname}${window.location.port ? `:${window.location.port}` : ''}`
  };
  return (
    <>
      <Box>
        <Sidebar
          collapsed={show}
          transitionDuration={500}
          width="15.5em"
          backgroundColor={"white"}
          rootStyles={{
            height: "100vh",
            boxShadow: "0 3px 3em #f0f0f0",
          }}
        >
          {!show && (
            <Box
              sx={{
                textAlign: "right",
                p: 1,
                cursor: "pointer",
                position: "sticky",
                top: "0em",
                bgcolor: "white",
                zIndex: 99
              }}
            >
              <ArrowBackIosNewOutlined onClick={() => setHide(true)} />
            </Box>
          )}
          {show && (
            <Box
              sx={{
                textAlign: "center",
                p: 1,
                cursor: "pointer",
                position: "sticky",
                top: "0em",
                bgcolor: "white",
              }}
            >
              <MenuOutlined onClick={() => setHide(false)} />
            </Box>
          )}
          {!show && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "2em",
                p: 1,
                position: "sticky",
                top: "2.7em",
                bottom: "2em",
                zIndex: 99,
                bgcolor: "white",
                marginBottom: "1.5em",
              }}
            >
              <Avatar
                src={companyData && companyData?.company_logo} alt={companyData && companyData?.company_logo}
                sx={{ width: { xs: 50, sm: 80 }, height: { xs: 50, sm: 80 }, mt: "-2em" }}
              />
              {/* <img src="/logo_2.jpeg" alt="logo_2.jpeg" style={{ width: "auto", height: 70, marginTop: "0.5em", border: "2px lightgrey solid", padding: "0 0.3em",boxShadow: "0 0 5px black" }} /> */}
            </Box>
          )}
          <Menu closeOnClick transitionDuration={500}>
            {/* {permissions?.side_nav.can_manage_dashboard && <Items label={'Dashboard'} icon={<DashboardRoundedIcon size={25} />} color={"black"} onClickAction={() => handleNavigation('/myLeads')} active={activeItem === '/myLeads'} />} */}
            {(role === "SAdmin" || role === "CAdmin") && (
              <Items
                label={"Dashboard"}
                icon={<Dashboard size={25} />}
                color={"black"}
                onClickAction={() => handleNavigation("/myLeads")}
                active={activeItem === "/myLeads"}
              />
            )}
            <Items
              label={"Manage Clients"}
              icon={<ContactPageRoundedIcon size={25} />}
              color={"black"}
              onClickAction={() =>
                handleNavigation("/myLeads/ClientDetails")
              }
              active={activeItem === "/myLeads/ClientDetails"}
            />
            {(role !== "SAdmin") && (
              <Items
                label={"Manage Enquiry"}
                icon={<SummarizeRoundedIcon size={25} />}
                color={"black"}
                onClickAction={() =>
                  handleNavigation("/myLeads/EnquiryDetails")
                }
                active={activeItem === "/myLeads/EnquiryDetails"}
              />
            )}
            <Items
              label={"Reports"}
              icon={<AssessmentRoundedIcon size={25} />}
              color={"black"}
              onClickAction={() => handleNavigation("/myLeads/ReportDetails")}
              active={activeItem === "/myLeads/ReportDetails"}
            />
            <SubMenu
              icon={<Settings />}
              style={{ color: "#0077AA" }}
              label="Setups"
            >
              <Box>
                <Items
                  label={`Manage ${rolesNames?.users_alt_label || "User"}`}
                  icon={<PeopleRoundedIcon size={25} />}
                  color={"black"}
                  onClickAction={() => handleNavigation("/myLeads/CompanyAdmin")}
                  active={activeItem === "/myLeads/CompanyAdmin"}
                />
                {(role === "SAdmin" || role === "CAdmin") && (
                  <Items
                    label={`Manage ${rolesNames?.company_alt_label || "Company"}`}
                    icon={<LocationCityRoundedIcon size={25} />}
                    color={"black"}
                    onClickAction={() =>
                      handleNavigation("/myLeads/ManageComapany")
                    }
                    active={activeItem === "/myLeads/ManageComapany"}
                  />
                )}
                {(role === "DAdmin" || role === "CAdmin") && (
                  <Items
                    label={`Manage ${rolesNames?.dept_alt_label || "Department"}`}
                    icon={<AddBusinessRoundedIcon size={25} />}
                    color={"black"}
                    onClickAction={() => handleNavigation("/myLeads/DeptDetails")}
                    active={activeItem === "/myLeads/DeptDetails"}
                  />
                )}
                {(role === "SAdmin") && (
                  <Items
                    label={"Company Setup"}
                    icon={<ContactPageRoundedIcon size={25} />}
                    color={"black"}
                    onClickAction={() =>
                      handleNavigation("/myLeads/roles-setup")
                    }
                    active={activeItem === "/myLeads/roles-setup"}
                  />
                )}
                {/* {(role === "SAdmin") && (
                  <Items
                    label={"Dropdown"}
                    icon={<SummarizeRoundedIcon size={25} />}
                    color={"black"}
                    onClickAction={() =>
                      handleNavigation("/myLeads/dropdown-setup")
                    }
                    active={activeItem === "/myLeads/dropdown-setup"}
                  />
                )} */}
              </Box>
            </SubMenu>
            <Items
              label={"Log Out"}
              icon={<ExitToAppRoundedIcon size={25} />}
              color={"black"}
              onClickAction={() => logOutHandler()}
            />
            {!show && (
              <Box sx={{ position: { xs: "fixed" }, display: "flex", justifyContent: "center", alignItems: "center", bottom: "2em", left: { sm: "2%" }, px: { xs: "1em", xl: 0 }, zIndex: -999 }}>
                <img
                  src="/my_lead_logo.png"
                  alt="/my_lead_logo.png"
                  style={{
                    fontSize: "0.7em",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  height={50}
                />
              </Box>
            )}
          </Menu>
          {!show && (
            <Box sx={{ position: { xs: "fixed" }, display: "flex", justifyContent: "center", alignItems: "center", bottom: "1em", left: { sm: "2%" }, px: { xs: "1em", xl: 0 }, zIndex: -999 }}>
              <Typography
                sx={{
                  fontSize: "0.7em",
                  color: "black",
                  fontWeight: "bold",
                }}
                variant="body1"
              >
                Powered by
                <a
                  href="https://www.ganakalabs.com/"
                  target="_rebel"
                  style={{ color: "blue" }}
                >
                  {" "}
                  ganakalabs.com
                </a>
              </Typography>
            </Box>
          )}
        </Sidebar>
      </Box>
    </>
  );
};

export default SideBarComponent;
