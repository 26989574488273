// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app,
.content {
    height: 100Vh;
    width: 100%;
    overflow-y: scroll;
    /* background-color: #F4F6FF; */
}

.content {
    background-color: #dff6fb;
}

.app {
    overflow: hidden;
    display: flex;
}

.upload_image {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    /* border: 2px red solid; */
}

.pointer {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,WAAW;IACX,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,iCAAiC;IACjC,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".app,\n.content {\n    height: 100Vh;\n    width: 100%;\n    overflow-y: scroll;\n    /* background-color: #F4F6FF; */\n}\n\n.content {\n    background-color: #dff6fb;\n}\n\n.app {\n    overflow: hidden;\n    display: flex;\n}\n\n.upload_image {\n    display: flex;\n    flex-direction: column !important;\n    justify-content: center;\n    align-items: center;\n    /* border: 2px red solid; */\n}\n\n.pointer {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
