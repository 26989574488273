import { Navigate } from "react-router-dom";
import { UseAuth } from "../customhooks/UseAuth";

const AuthGuard = ({ children }) => {
    const { role } = UseAuth();
    // console.log("AuthGuard", role)
    const authRoles = [
        "SAdmin",
        "CAdmin",
        "DAdmin",
        "DUser",
    ]
    if (role === undefined) {
        return <Navigate to={'/signin'} />
    }
    const rolesMatch = authRoles.includes(role);
    // console.log("rolesMatch==>", rolesMatch)
    if (!rolesMatch) {
        return <Navigate to={'/signin'} />
    }
    return children;
}

export default AuthGuard