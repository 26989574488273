// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.css-1j9kmqg-MuiDataGrid-toolbarContainer {
  background-color: white;
}

.css-i1r5v {
  background-color: transparent !important;
}

/* .highlighted-date {
  background-color: #006edc !important;
  border-radius: 6px;
  font-weight: bold;
  color: #FFFFFF;
} */
.highlighted-date {
  position: relative;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

/* Styles for past dates */
.highlighted-date-red .dot {
  background-color: #dc0000;
  /* Red for past dates */
}

/* Styles for future dates */
.highlighted-date-green .dot {
  background-color: #00bfae;
  /* Green for future dates */
}

.react-calendar {
  width: 90% !important;
  max-width: 90% !important;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: solid transparent 1px !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;;;;;GAKG;AACH;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,WAAW;EACX,UAAU;AACZ;;AAEA,0BAA0B;AAC1B;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA,4BAA4B;AAC5B;EACE,yBAAyB;EACzB,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,iBAAiB;EACjB,yBAAyB;EACzB,yCAAyC;EACzC,+BAA+B;AACjC;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.css-1j9kmqg-MuiDataGrid-toolbarContainer {\n  background-color: white;\n}\n\n.css-i1r5v {\n  background-color: transparent !important;\n}\n\n/* .highlighted-date {\n  background-color: #006edc !important;\n  border-radius: 6px;\n  font-weight: bold;\n  color: #FFFFFF;\n} */\n.highlighted-date {\n  position: relative;\n}\n\n.dot {\n  height: 10px;\n  width: 10px;\n  border-radius: 50%;\n  display: inline-block;\n  position: absolute;\n  bottom: 5px;\n  right: 5px;\n}\n\n/* Styles for past dates */\n.highlighted-date-red .dot {\n  background-color: #dc0000;\n  /* Red for past dates */\n}\n\n/* Styles for future dates */\n.highlighted-date-green .dot {\n  background-color: #00bfae;\n  /* Green for future dates */\n}\n\n.react-calendar {\n  width: 90% !important;\n  max-width: 90% !important;\n  background: white;\n  border: 1px solid #a0a096;\n  font-family: Arial, Helvetica, sans-serif;\n  line-height: 1.125em !important;\n}\n\n.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-cell:focus {\n  outline: solid transparent 1px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
