import { configureStore } from "@reduxjs/toolkit";
import dashboardSlice from "./reducerSlice/dashboardSlice";
import userSlice from "./reducerSlice/userSlice";
import companySlice from "./reducerSlice/companySlice";
import deptSlice from "./reducerSlice/deptSlice";
import enquirySlice from "./reducerSlice/enquirySlice";
import reportSlice from "./reducerSlice/reportSlice";
import clientSlice from "./reducerSlice/ClientSlice";
import subscriptionInvoicePayment from "./reducerSlice/subscriptionInvoicePayment";
import activitySlice from "./reducerSlice/activitySlice";
import setUpSlice from "./reducerSlice/SetUpSlice";
import paymentSlice from "./reducerSlice/payment";

export const store = configureStore({
  reducer: {
    dashboard: dashboardSlice,
    auth: userSlice,
    createDept: deptSlice,
    company: companySlice,
    enquiry: enquirySlice,
    report: reportSlice,
    client: clientSlice,
    invoicesubscription: subscriptionInvoicePayment,
    activity: activitySlice,
    rolesetup: setUpSlice,
    payments: paymentSlice,
  },
});
