import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
import Swal from "sweetalert2";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("my_leads");

// API headers including token
const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
export const coreDomainApi = createAsyncThunk(
  "coreDomain/authCoreDomain",
  async (domain) => {
    // console.log("domain==>", domain)
    let response;
    try {
      response = await BaseUrl.get(`my_Leads/company/domain/${domain}`);
      // console.log("response.data", response.data)
      return response.data;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      Swal.fire({
        icon: "error",
        title: "Domain Name",
        text: `${error?.response?.data?.message}`,
        showConfirmButton: false,
        timer: 3000
      })
      throw error; // Ensure the error is propagated
    }
  }
);
export const loginApi = createAsyncThunk(
  "login/authlogin",
  async ({ data, Swal }) => {
    // console.log("data==>", data)
    let response;
    try {
      response = await BaseUrl.post(`/my_Leads/auth/signin`, data);
      // console.log("response.data", response.data)
      return response.data;
    } catch (error) {
      Swal.fire({
        title: "Login failed!",
        text: `${error.response.data.message}`,
        icon: "error",
        showConfirmButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const getAllUserdata = createAsyncThunk(
  "users/getAllUserdata",
  async () => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.get(
        `/my_Leads/auth/users`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data);
      return response.data.data;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);

export const getSingleUserdata = createAsyncThunk(
  "users/getSingleUserdata",
  async (id) => {
    try {
      const response = await BaseUrl.get(
        `/my_Leads/auth/users/${id}`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const createUserdata = createAsyncThunk(
  "users/createUserdata",
  async (data) => {
    try {
      const response = await BaseUrl.post(
        `my_Leads/auth/signup`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const updateeUserdata = createAsyncThunk(
  "users/updateeUserdata",
  async (data) => {
    try {
      const response = await BaseUrl.patch(
        `my_Leads/auth/users`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
// get department dropdata
export const getDeptDropdata = createAsyncThunk(
  "users/getDeptDropdata",
  async () => {
    try {
      const response = await BaseUrl.get(
        `my_Leads/department/deptDropDown`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
//get departmentDropData By Sending CompanyId
export const getDeptByCompId = createAsyncThunk(
  "users/getDeptByCompId",
  async (cid) => {
    try {
      const response = await BaseUrl.get(
        `my_Leads/department/deptByCompId/${cid}`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
//company users list
export const getUserListData = createAsyncThunk(
  "users/getUserListData",
  async (id) => {
    try {
      const response = await BaseUrl.get(
        `my_Leads/auth/listUserByCompID/${id}`,
        getAuthHeaders()
      );
      // console.log("getUserListData==>", response.data.data)
      return response.data.data;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
//dept users list
export const getUserListDept = createAsyncThunk(
  "users/getUserListDept",
  async (id) => {
    try {
      const response = await BaseUrl.get(
        `my_Leads/auth/listUserByDeptID/${id}`,
        getAuthHeaders()
      );
      // console.log("getUserListDept==>", response.data.data)
      return response.data.data;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
export const fetchPermissions = createAsyncThunk(
  "/my_Leads/fetchPermissions",
  async (data) => {
    try {
      const response = await BaseUrl.get(
        `/my_Leads/permissions`,
        getAuthHeaders()
      );
      // console.log("response.data pewrmsioin: ", response.data)
      return response.data;
    } catch (error) {
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);
// export const fetchPermissions = "{\"side_nav\":{\"can_manage_dashboard\":true,\"can_manage_company\":true,\"can_manage_departments\":true,\"can_manage_users\":true,\"can_manage_enquiries\":true},\"manage_company\":{\"can_add_company\":true,\"can_edit_company\":true,\"can_delete_company\":true,\"can_view_company\":true},\"manage_department\":{\"can_add_department\":true,\"can_edit_department\":true,\"can_delete_department\":true,\"can_view_department\":true},\"manage_users\":{\"can_add_users\":true,\"can_edit_users\":true,\"can_delete_users\":true,\"can_view_users\":true},\"manage_enquiries\":{\"can_add_enquiry\":true,\"can_edit_enquiry\":true,\"can_delete_enquiry\":true,\"can_view_enquires\":true}}"
export const forgotPassword = createAsyncThunk(
  "/my_Leads/forgotPassword",
  async (userDetails) => {
    console.log("user forgot password details===>", userDetails);
    try {
      const response = await BaseUrl.patch(
        "/my_Leads/auth/forgetPassword",
        userDetails
      );
      // console.log("response.data forgotPassword: ", response.data)
      return response.data;
    } catch (error) {
      alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);

// contact form mail

export const emailSender = createAsyncThunk(
  "contactform/emailSender",
  async ({ mailData, Swal }) => {
    try {
      const response = await BaseUrl.post(
        `my_Leads/mail/`,
        mailData,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      console.error(`${error.response.data.message}`)
      // alert(`${error.response.data.message}`)
      throw error; // Ensure the error is propagated
    }
  }
);

// login SLICE
export const loginSlice = createSlice({
  name: "auth",
  initialState: {
    userData: [],
    singleUserData: {},
    userList: [],
    deptUserList: [],
    dropData: [],
    permission: "",
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserdata.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
      })
      .addCase(getAllUserdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getSingleUserdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleUserdata.fulfilled, (state, action) => {
        state.loading = false;
        // state.singleUserData = action.payload.userData;
        state.singleUserData = action.payload;
      })
      .addCase(getSingleUserdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getDeptDropdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeptDropdata.fulfilled, (state, action) => {
        state.loading = false;
        state.dropData = action.payload;
      })
      .addCase(getDeptDropdata.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    //comp
    builder
      .addCase(getUserListData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserListData.fulfilled, (state, action) => {
        state.loading = false;
        state.userList = action.payload;
      })
      .addCase(getUserListData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    //dept
    builder
      .addCase(getUserListDept.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserListDept.fulfilled, (state, action) => {
        state.loading = false;
        state.deptUserList = action.payload;
      })
      .addCase(getUserListDept.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading = false;
        state.permission = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(getDeptByCompId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeptByCompId.fulfilled, (state, action) => {
        state.loading = false;
        state.dropData = action.payload;
      })
      .addCase(getDeptByCompId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
  },
});

// SELECTORS
export const getUsersData = (state) => state.auth.userData;
export const getUsersList = (state) => state.auth.userList;
export const getDeptUsersList = (state) => state.auth.deptUserList;
export const getSingleUsersData = (state) => state.auth.singleUserData;
export const getDepartmentDropData = (state) => state.auth.dropData;
export const getPermissions = (state) => state.auth.permission;
export const loginerror = (state) => state.auth.loading;

// EXPORTS
export default loginSlice.reducer;
