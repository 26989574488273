import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

const FooterComponent = () => {
    return (
        <Box sx={{ height: "10vh", width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", gap: { xs: 1, sm: 2 }, px: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                <Box sx={{ fontSize: { xs: "0.7em", sm: "1em" } }}>
                    <Link to={'/privacy'} style={{ textDecoration: "none", color: "blue", fontWeight: "bold" }}>Privacy</Link>
                </Box>
                <Box sx={{ fontSize: { xs: "0.7em", sm: "1em" } }}>
                    <Link to={'/terms-and-conditions'} style={{ textDecoration: "none", color: "blue", fontWeight: "bold" }}>Terms of Services</Link>
                </Box>
            </Box>
            <Box sx={{ fontSize: { xs: "0.7em", sm: "1em" } }}>Powered by <Link to='https://www.ganakalabs.com/' target='_chaintra_stingy' style={{ textDecoration: "none", color: "blue", fontWeight: "bold" }}>Ganaka Labs</Link></Box>
        </Box>
    )
}

export default FooterComponent