import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    fullPage: {
        height: '100vh',
        width: '100%',
        backgroundImage: 'url("/404-error.jpg")', // replace with your image URL
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        display: "flex",
        justifyContent: "center",
        alignItems: 'center'
    },
    '@keyframes backgroundChange': {
        '0%': { backgroundColor: '#4287f5' },
        '33%': { backgroundColor: '#42b6f5' },
        '66%': { backgroundColor: '#1f4ce0' },
        '100%': { backgroundColor: '#420bb8' }
    },
    animatedButton: {
        animation: `$backgroundChange 3s infinite`
    }
}));

const ErrorPage = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    return (
        <Grid container className={classes.fullPage}>
            <Box sx={{ mt: "-6em" }}>
                <Button variant='contained' className={classes.animatedButton} onClick={() => navigate(-1)}>Previous page</Button>
            </Box>
        </Grid>
    )
}

export default ErrorPage;
