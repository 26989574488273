import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("my_leads");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

//dept SetUp list
export const getKeyAPI = createAsyncThunk(
    "report/getKeyAPI",
    async () => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/razor-pay/get-key/`,
                getAuthHeaders()
            );
            // console.log("getSubscriptionPaymentAPI==>", response.data)
            return response.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const getPaymentDataAPI = createAsyncThunk(
    "report/getPaymentDataAPI",
    async (id) => {
        try {
            const response = await BaseUrl.get(
                `my_Leads/customerpayment/payment/${id}`,
                getAuthHeaders()
            );
            // console.log("getSubscriptionPaymentAPI==>", response.data)
            return response.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            throw error; // Ensure the error is propagated
        }
    }
);
export const createOrder = createAsyncThunk(
    "report/createOrder",
    async ({ orderData, Swal }) => {
        try {
            const response = await BaseUrl.post(
                `my_Leads/razor-pay/order`, orderData,
                getAuthHeaders()
            );
            // console.log("createOrder==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            Swal.fire({
                title: "Order!",
                text: `${error.response.data.message}`,
                icon: "warning",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
            })
            throw error; // Ensure the error is propagated
        }
    }
);
export const getSubscriptionPaymentAPI = createAsyncThunk(
    "report/getSubscriptionPaymentAPI",
    async ({ subcripData, Swal }) => {
        try {
            const response = await BaseUrl.post(
                `my_Leads/customersub/sub-access/`, subcripData,
                getAuthHeaders()
            );
            // console.log("getSubscriptionPaymentAPI==>", response.data.data)
            return response.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            Swal.fire({
                title: "Payment!",
                text: `${error.response.data.message}`,
                icon: "warning",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
            })
            throw error; // Ensure the error is propagated
        }
    }
);

export const updateGstNumberAPI = createAsyncThunk(
    "report/updateGstNumberAPI",
    async ({ gstData, Swal }) => {
        try {
            const response = await BaseUrl.patch(
                `my_Leads/customers/gstn-update`, gstData,
                getAuthHeaders()
            );
            // console.log("updateGstNumberAPI==>", response.data.data)
            return response.data.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            Swal.fire({
                title: "GST!",
                text: `${error.response.data.message}`,
                icon: "error",
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
            })
            throw error; // Ensure the error is propagated
        }
    }
);




export const paymentSlice = createSlice({
    name: "payments",
    initialState: {
        paymentData: [],
        key: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubscriptionPaymentAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSubscriptionPaymentAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.paymentData = action.payload;
            })
            .addCase(getSubscriptionPaymentAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
        builder
            .addCase(getKeyAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getKeyAPI.fulfilled, (state, action) => {
                state.loading = false;
                state.key = action.payload;
            })
            .addCase(getKeyAPI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            });
    },
});
export const SubscriptionList = (state) => state.payments.paymentData;
export const keyRaz = (state) => state.payments.key;
export const paymenterror = (state) => state.payments.loading;

// EXPORTS
export default paymentSlice.reducer;
