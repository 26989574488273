import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UseAuth } from "../customhooks/UseAuth";
import { useNavigate } from "react-router-dom";
import ProfileTopBarMenu from "../utils/ProfileTopBarMenu";
import { useDispatch, useSelector } from "react-redux";
import { companyRolesBasedOnCId, getCompanyRoles } from "../redux/reducerSlice/SetUpSlice";

const TopBarComponent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userId, name, role, department_name, company_id, company_name } = UseAuth();
  // console.log("company_id", company_id)
  const [anchorEl, setAnchorEl] = useState(null);
  const rolesNames = useSelector(companyRolesBasedOnCId)
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAccount = () => {
    navigate(`/myLeads/UpdateCompanyAdmin/${userId}`);
    setAnchorEl(null);
  };
  const logOut = () => {
    let currentHostname = window.location.hostname;
    // Split the hostname to separate subdomain from the rest
    let newHostname;
    if (currentHostname.includes('localhost')) {
      newHostname = `localhost`;
    } else {
      newHostname = `myleads.in`;
    }
    const parts = currentHostname.split('.');
    console.log("parts", parts)
    localStorage.clear("my_leads");
    localStorage.clear("company_logo");
    window.location.href = `${window.location.protocol}//${newHostname}${window.location.port ? `:${window.location.port}` : ''}`
    setAnchorEl(null);
  };
  // console.log("company name", company_name);
  // console.log("role===>", UseAuth())
  const styles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItem: "center",
    position: "sticky",
    top: "0px",
    zIndex: 99,
    boxSadhow: "5px 0 10px black",
    p: 1,
  };
  useEffect(() => {
    const roleFnc = () => {
      dispatch(getCompanyRoles(company_id))
    }
    roleFnc()
  }, [dispatch, company_id])
  return (
    <>
      <Box sx={styles} bgcolor={"white"} borderBottom={"2px lightgrey solid"}>
        {/* <Avatar style={{ width: 60, height: 60, boxShadow: "0 0 5px black" }} src='/samyojak_logo.jpeg' alt='samyojak_logo.jpeg' /> */}
        <div>
          <Typography fontSize={"1.6em"} fontWeight="bold" color={"#1976D2"}>
            Welcome to My Leads
          </Typography>
          {/* <Typography variant='h6' fontWeight={'bold'} color={"#cf2e2e"}>
                        Enquiry Capture Pro
                    </Typography> */}
        </div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div>
            <Typography
              variant="body"
              fontSize={"1.2em"}
              color={"#1976D2"}
              fontWeight={"bold"}
            >
              {name}
            </Typography>
            <br />
            <Typography
              variant="body"
              fontSize={"1.0em"}
              color={"#cf2e2e"}
              fontWeight={"bold"}
            >
              {role === "SAdmin"
                ? "Super Admin"
                : role === "CAdmin"
                  ? (rolesNames?.company_head_label || "Company Admin")
                  : role === "DAdmin"
                    ? (rolesNames?.department_head_label || "Department Admin")
                    : role === "DUser"
                      ? (rolesNames?.user_head_label || "Department User")
                      : ""}
            </Typography>
            <br />
            {(role === "DAdmin" || role === "DUser") && (
              <Typography
                variant="body"
                fontSize={"1.1em"}
                color={"#1976D2"}
                fontWeight={"bold"}
              >
                {department_name}
              </Typography>
            )}

            {role === "CAdmin" && (
              <Typography
                variant="body"
                fontSize={"1.1em"}
                color={"#1976D2"}
                fontWeight={"bold"}
              >
                {company_name}
              </Typography>
            )}
          </div>
          <div>
            <div>
              <ProfileTopBarMenu anchorel={anchorEl} handleclose={handleClose} handleaccount={handleAccount} handlemenu={handleMenu} logout={logOut} />
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default TopBarComponent;
